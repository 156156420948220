import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Form, Grid, Header, Message, Segment } from "semantic-ui-react";
import md5 from 'md5';
import { StatusCodes } from 'http-status-codes';

import { AuthenticatedContext } from '../authenticated.context';



const Login = () => {
	const [user, setUser] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState(false);

	const navigate = useNavigate();
	const [authenticated, setAuthenticated] = useContext(AuthenticatedContext)

	const handleLogin = (user, password) => {
		fetch(`/user-login/${user.trim()}-${md5(user.trim()+password.trim())}?nonce=${new Date().toISOString()}`)
			.then( (response) => {
				if(response.status !== StatusCodes.OK) {
					setError(true);
				} else {
					console.log("login succesful");
					setAuthenticated(true);
					navigate('/qr');
				}
			});
	}

	return (
		<Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
			<Grid.Column style={{ maxWidth: 450 }}>
				<Header as='h2' color='teal' textAlign='center'>
					Iniciar sesión al sistema
				</Header>
				<Form size='large' error={error} onSubmit={(e) => { e.preventDefault(); handleLogin(user, password);}}>
					<Segment stacked>
						<Form.Input
							fluid
							icon='user'
							iconPosition='left'
							value={user}
							onChange={(e) => setUser(e.target.value)}
							placeholder='Nombre de usuario' />

						<Form.Input
							fluid
							icon='lock'
							iconPosition='left'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							placeholder='Contraseña'
							type='password'
						/>

						<Button color='teal' fluid size='large'>
							Iniciar sesión
						</Button>
					</Segment>
					<Message
						error
						header='Ingreso incorrecto'
						content='El nombre de usuario y/o contraseña es incorrecto.'
					/>
				</Form>
			</Grid.Column>
		</Grid>
	)
};

export default Login;
