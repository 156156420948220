import React from 'react';
import { Grid } from "semantic-ui-react";

const PageNotFound = () => {
    return (
        <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 450 }}>
                <h1>404</h1>
				<h2>Página no encontrada</h2>
			</Grid.Column>
        </Grid>
    );
}

export default PageNotFound;