import './App.css';
import {HashRouter, Navigate, Route, Routes} from "react-router-dom";
import {useState} from 'react';

import Login from './views/login';
import Qr from "./views/qr";
import PageNotFound from './error-pages/404';
import ForbiddenPage from './error-pages/403';
import {AuthenticatedContext} from './authenticated.context';

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  return (
    <AuthenticatedContext.Provider value={[authenticated, setAuthenticated]}>
      <HashRouter>
          <Routes>
              <Route path="/" element={<Navigate to="/login"/>}/>
              <Route exact path="/qr" element={authenticated? <Qr/> : <ForbiddenPage/>} />
              <Route exact path="/login" element={<Login />} />
              <Route path="/404" element={<PageNotFound/>} status={404}/>
              <Route path="*" element={<Navigate to="/404"/>}/>
          </Routes>
      </HashRouter>
    </AuthenticatedContext.Provider>
  );
}

export default App;
