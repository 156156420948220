import React, {useState, useContext} from 'react';
import QRCode from "react-qr-code";
import {useNavigate} from 'react-router-dom';
import {Button, Form, Grid, Header, Message, Segment} from "semantic-ui-react";
import {AuthenticatedContext} from '../authenticated.context';
import '../styles/qr.css';

const saveSvgAsPng = require('save-svg-as-png')


const QrComponent = () => {
	const [value, setValue] = useState("");
	const [message, setMessage] = useState("");
	const [valid, setValid] = useState(false);

	const [authenticated, setAuthenticated] = useContext(AuthenticatedContext)
	const navigate = useNavigate();

	const handleChange = (event) => {
		setValue(event.target.value);
		setValid(/^([0-9]{12}-[0-9]{2})$/.test(event.target.value));
	}

	const handleClick = () => {
		setMessage(value);
	}

	const saveFile = () => {
		if (message) {
			saveSvgAsPng.saveSvgAsPng(document.getElementById("diagram"), `${message}.png`, {scale: 2.0});
		}
	}

	return (
		<Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
			<Grid.Column style={{maxWidth: 450}}>
				<Header as='h2' color='teal' textAlign='center'>
					Generar credencial provisoria
				</Header>
				<Form size='large'>
					<Segment stacked>
						<Form.Input fluid icon='qrcode' placeholder='Numero de afiliado' iconPosition='left'
									type='XXXXXXXXXXXX-XX' onChange={(e) => handleChange(e)}/>

						<Button color='teal' fluid size='large' disabled={!valid} onClick={() => handleClick()}
								style={{marginBottom: "20px"}}>
							Generar
						</Button>

						<div className="qr-container">
							{<QRCode
								id="diagram"
								onClick={() => saveFile()}
								size={256}
								style={{
									height: "auto",
									maxWidth: "256px",
									width: "256px",
									opacity: message ? "1" : "0.1",
									cursor: message ? "pointer" : "default"
								}}
								value={message}
								viewBox={`0 0 256 256`}
							/>}
							{message && <div className="download-square">
								<span><i className="download icon"></i>
								Descargar</span></div>}
						</div>
					</Segment>
				</Form>
				<Message>
					<Button color='gray' fluid size='large' onClick={() => {
						setAuthenticated(false);
						navigate('/')
					}}>
						Cerrar sesión
					</Button>
				</Message>
			</Grid.Column>
		</Grid>
	);
}

export default QrComponent;
