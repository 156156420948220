import React from 'react';
import { Grid } from "semantic-ui-react";

const ForbiddenPage = () => {
    return (
        <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 450 }}>
                <h1>403</h1>
				<h2>Acceso denegado</h2>
			</Grid.Column>
        </Grid>
    );
}

export default ForbiddenPage;